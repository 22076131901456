@keyframes textFade {
  0%, 100% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(10px);
    opacity: 0.7;
  }
}

.fadeText {
  animation: textFade 5s infinite;
}
